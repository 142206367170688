const Time = {}

Time.toDateString = function (timestamp = null) {
  let date = new Date()
  if (timestamp) {
    date.setTime(timestamp * 1000)
  }
  let y = date.getFullYear()
  let m = date.getMonth() + 1
  let d = date.getDate()
  return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + " " + date.toTimeString().substr(0, 8)
}

Time.toTimestamp = function (timeStr = null) {
  let date = new Date()
  if (timeStr) {
    date = timeStr
    date = date.substring(0, 19)
    date = date.replace(/-/g, '/')
    date = new Date(date)
  }
  date = date.getTime()
  return Math.floor(date)
}

Time.formatTime = function (timestamp) {
  let hh
  let mm
  let ss
  //传入的时间为空或小于0
  if (timestamp == null || timestamp < 0) {
    return
  }
  //得到小时
  hh = timestamp / 3600 | 0
  timestamp = parseInt(timestamp) - hh * 3600
  if (parseInt(hh) < 10) {
    hh = "0" + hh
  }
  //得到分
  mm = timestamp / 60 | 0
  //得到秒
  ss = parseInt(timestamp) - mm * 60
  if (parseInt(mm) < 10) {
    mm = "0" + mm
  }
  if (ss < 10) {
    ss = "0" + ss
  }
  return hh + ":" + mm + ":" + ss
}

export default Time
