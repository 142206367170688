<template>
  <div class="recommended" :class="{ isMobile }">
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      size="small"
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
    >
      <el-table-column prop="nickname" label="昵称" />
      <el-table-column prop="mobile" label="手机" width="110" />
      <el-table-column prop="date_joined" label="注册日期" />
      <el-table-column prop="vipLevel" label="vip等级" />
      <el-table-column prop="vipEnd" label="VIP到期时间"> </el-table-column>
      <!-- <el-table-column prop="id" label="会员推荐id" width="200" /> -->
      <!-- <el-table-column prop="remark" label="备注" /> -->
    </el-table>
  </div>
</template>

<script>
import { getUserList } from '@/api/user'
import Time from '@/utils/time'

export default {
  components: {},

  data() {
    return {
      params: {
        page: 10,
        limit: 20
      },
      tableData: [],
      totalCount: 0,
      userInfo: this.$store.state.userInfo
    }
  },

  mounted() {
    this.params.recommended_id = this.userInfo.recommended_id
    this.load()
  },

  methods: {
    load() {
      getUserList(this.params)
        .then((response) => {
          const { documents, total } = response.data
          documents.forEach((element) => {
            element.vipEnd = this.toStringTime(element.vip_expired_time)
          })
          this.tableData = documents
          this.totalCount = total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    toStringTime(timestamp) {
      return Time.toDateString(timestamp)
    }
  }
}
</script>

<style lang="scss" scoped></style>
